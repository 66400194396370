import React, {memo} from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const MemoPortfolioPost = memo(function PortfolioPost(props) {
  //console.log(props);
  let post = props.data;
  //Get values to render
  let title = post.title,
  link = post.companylink,
  sectors = post.sectors,
  featuredImage = post.featuredimage,
  imageWidth = featuredImage.childImageSharp.gatsbyImageData.width,
  imageHeight = featuredImage.childImageSharp.gatsbyImageData.height;

    return (
        <div className="grid-cell small" data-sector={sectors}>
          <a href={link} target="_blank" rel="noreferrer">
            <article
            className = 'portfolio-list-item is-child' >
              {featuredImage ? (
                <div className="featured-thumbnail is-flex is-justify-content-end">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: featuredImage,
                      alt: `featured image thumbnail for post ${title}`,
                      width: imageWidth,
                      height: imageHeight
                    }}
                  />
                </div>
              ) : null }
              </article>
            </a>
        </div>
    )
})

MemoPortfolioPost.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  sectors: PropTypes.array,
  featuredImage: PropTypes.object
}

export default MemoPortfolioPost;