import React from "react"
import _ from "lodash";

const FilterTemplate = (props) => {
  //console.log(props);
  const values = props.filters;
    
    return (
        <div className="filter-container has-text-centered">
           {/* <div className="filter-type-wrapper">
                <button id={`filter-item-all`} onClick={()=> props.updateFilter('all')} className={`filter-type${props.showSectors && !props.showAll ? ' filter-selected' : ''}${props.showAll ? ' filter-active' : ''}`}>All</button>
                <button id={`filter-item-spotlight`} onClick={()=> props.updateFilter('spotlight')} className='filter-type'>Spotlights</button>
            </div>*/}
            { props.showSectors ? (
              <div className="filter-sector-wrapper">
              <button id='filter-item-all' className='filter-sector' onClick={()=> props.updateFilter('all')} key='all' value={_.kebabCase('all')}>All Sectors</button>
                {values.map(value => (
                      <button id={`filter-item-${value}`} className='filter-sector' onClick={()=> props.updateFilter(value)} key={value} value={_.kebabCase(value)}>{value}</button>
                  ))}
             </div>
            ) : null }

        </div>
    )
}

export default FilterTemplate
