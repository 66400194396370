import React from 'react';
import PropTypes from 'prop-types';
import PostLoop from './PostLoop';
import SpotlightPostLoop from './SpotlightPostLoop';

const PortfolioGrid = (props) => {
  //console.log(props);
  //Outputs companies and featured founders. Outputs founders first with css grid position defined in _portfolio.scss. The company grid is filled in around those afterwards.
  const fposts  = props.featured;
  const cposts = props.portfolio;
  const filter = props.filter

  //Only displays featured founders if there are enough standard company grid items to not break the grid
    return (
      <section className={`grid portfolio ${filter === 'spotlight' ? "spotlight-grid" : ""}`} data-ref="container">
        <PostLoop posts={fposts} isFeatured={true}/>
        {(filter !== 'spotlight') ? <PostLoop posts={cposts} isFeatured={false} /> : <SpotlightPostLoop posts={fposts} />}
      </section>
    )
}

PortfolioGrid.propTypes = {
  featured: PropTypes.object,
  portfolio: PropTypes.object,
  filter: PropTypes.string,
}

export default PortfolioGrid