import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import Content, {HTMLContent} from "../components/Content";
import PreviewCompatibleImage from './PreviewCompatibleImage';
import { Link } from "gatsby";

import Modal from 'react-modal';

const MemoPortfolioPost = memo(function PortfolioPost(props) {
  const [postModalOpen, setPostModalOpen] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  //console.log(props);
  let post = props.data;

    //Get Values to display
    let title = post.frontmatter.title,
    featuredImage = post.frontmatter.featuredimage,
    logoImage = post.frontmatter.logoimage,
    teaser = post.frontmatter.teaser,
    description = post.frontmatter.description;
    const sectors = post.frontmatter.sectors;
    const whyWeInvested = post.frontmatter.whyweinvested;
    const linkedIn = post.frontmatter.linkedin;
    const twitter = post.frontmatter.twitter;
    const website = post.frontmatter.website;
    const links = post.frontmatter.linkslist;
    const PageContent = HTMLContent || Content;

  const customStyles = { //Styles for modal
    overlay: {
      backgroundColor: '#2D2D2D',
      height: '100%',
      overflow: 'auto',
      zIndex: '30'
    },
    content: {
      top: '0',
      left: '0',
      right: 'auto',
      bottom: 'auto',
      padding: 0,
      border: 'none',
      borderRadius: '0'
    },
};

  function openModal() {
    setPostModalOpen(true);
    setScrollOffset(window.scrollY);
    document.body.setAttribute('style', `position: fixed; top: 0; left: 0; right: 0;`);
  }

  function closeModal() {
    setPostModalOpen(false);
    document.body.setAttribute('style', ``);
    window.scrollTo(0, scrollOffset);
  }
    return (
      <React.Fragment>
        <div className="grid-cell large is-featured">
              <button className="faux-anchor is-flex" onClick={openModal}>
                <article className ="portfolio-list-item is-child is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-flex-end">
                  {featuredImage ? (
                    <div className="featured-thumbnail is-flex is-justify-content-end">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: featuredImage,
                          alt: `featured image thumbnail for post ${title}`,
                          width: 390,
                          height: 225
                        }}
                      />
                    </div>
                  ) : null }
                  {logoImage ? (
                    <div className="logo-image is-flex">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: logoImage,
                          alt: `logo image thumbnail for post ${title}`,
                          width: 225,
                          height: 225
                        }}
                      />
                    </div>
                  ) : null }
                  {teaser ? (<p>{teaser}</p>) : null}
                </article>
              </button>
            </div>
             <Modal
                isOpen={postModalOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Founder Modal"
                portalClassName="ReactModalPortal Founder"
                preventScroll="true"
            >
                <div className="founder-modal-container is-flex"> 
                  <button className='close-founder-modal-bg faux-anchor' onClick={closeModal}></button>
                  <div className="founder-modal-aside is-flex is-flex-direction-column">
                    {featuredImage ? (
                      <div className="featured-thumbnail is-flex">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: featuredImage,
                            alt: `featured image thumbnail for post ${title}`,
                            width: 1500,
                            height: 960
                          }}
                        />
                      </div>
                    ) : null }
                    <div className="founder-modal-social-wrapper">
                      {linkedIn ? (<Link className="founder-modal-social" to={linkedIn}>LinkedIn</Link>) : null}
                      {twitter ? (<Link className="founder-modal-social" to={twitter}>Twitter</Link>) : null}
                      {website ? (<Link className="founder-modal-social" to={website}>Website</Link>) : null}
                    </div>
                    { links ? (
                      <ul className="founder-modal-links-list">
                         {(links.map(function(link){
                          return (
                            <li className="founder-modal-links-list-item">
                              <Link to={link.linkurl}>{link.linktext}</Link>
                            </li>
                          )}))}
                      </ul>
                    ) : null}
                  </div>
                  <article className='founder-modal-content is-child is-flex is-justify-content-space-between flex-direction-row-reverse'>
                    <div className="founder-modal-details">
                    <h6 className="founder-modal-heading founder-modal-heading-overline">Portfolio Spotlight</h6>
                    {logoImage ? (
                      <div className="logo-image is-flex">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: logoImage,
                            alt: `logo image thumbnail for post ${title}`,
                            width: 390,
                            height: 225
                          }}
                        />
                      </div>
                    ) : null }
                    {description ? (<PageContent content={description} className="founder-modal-description"></PageContent>) : null}
                    
                    {whyWeInvested ? (
                      <div className="founder-modal-why-we-invested">
                        <h3 className="founder-modal-heading">Why we invested</h3>
                        <p className="founder-modal-why-we-invested-content">{whyWeInvested}</p>
                      </div>
                    ) : null}

                    {sectors ? (
                      <p className="founder-modal-sectors">
                        {(sectors.map(function(sector, i){
                            return (
                              <>{sector}{i !== sectors.length - 1 ? ', ' : ''}</>
                            )
                          }))}
                      </p>
                    ) : null}
                    </div>
                  </article>
              </div>
            </Modal>
          </React.Fragment>
    )
})

MemoPortfolioPost.propTypes = {
  title: PropTypes.string,
  featuredimage: PropTypes.object,
  logoimage: PropTypes.object,
  whyweinvested: PropTypes.markdown,
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
  website: PropTypes.string,
  sectors: PropTypes.array,
  linkslist: PropTypes.array,
}

export default MemoPortfolioPost;