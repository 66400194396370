import React from 'react';
import PortfolioPost from './PortfolioPost';
import FounderPost from './FounderPost';

const PostLoop = (props) => {
  //console.log(props);
    const { posts } = props;
    const { isFeatured } = props;
    if(isFeatured && posts.edges){
      
      return (
        posts.edges.slice(0,8).map(({ node: post }) => (
            <FounderPost data={post} key={post.id}/>
        ))
      )
    }else if(posts.length > 0){
      let i = 0;
      return (
        posts.map(( post ) => (
          <PortfolioPost data={post} key={i++}/>
        ))
    )
    }else{
      return null;
    }

}

export default PostLoop