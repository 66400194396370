import React, {useReducer} from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { motion, AnimatePresence } from 'framer-motion';
import PortfolioGrid from "../components/PortfolioGrid";
import Filter from "../components/Filter";
import UpNext from "../components/UpNext";
import SEO from "../components/SEO";

const PortfolioIndexPage = (props) => {

  // PDF file
  let pdfFile = props.data.companies.frontmatter.pdffile;
  let pdfLabel = props.data.companies.frontmatter.pdflabel;

  //Get all tags
  let initFilters = parseTags(props.data.companies.frontmatter.portfolioitems);

  //Saving all the spotlight names
  let founderNames = []; 
  for (let i = 0; i < props.data.founders.edges.length; i++) {
    founderNames.push(props.data.founders.edges[i].node.frontmatter.title);
  }

  //console.log(props);
  const upNext = props.data.upnext.nodes[0].frontmatter.upnextlinks.upnextportfolio;
  //Initialize portfolio grid state
  const initialState =  {
    allCompanies: props.data.companies.frontmatter.portfolioitems,
    displayedCompanies: props.data.companies.frontmatter.portfolioitems,
    allFounders: props.data.founders,
    displayedFounders: props.data.founders,
    filterState: 'all',
    allFilters: initFilters,
    allFounderNames: founderNames
  }
  const [state, dispatch] = useReducer(stateReducer, initialState);
  //Iterates through allCompanies, saving all unique sector term.
  console.log("foo")
  function parseTags(allCompanies, allFilters=[]){
    allCompanies.map(function(i){
      //console.log(i);
      if(i.sectors !== null){
        i.sectors.map(function(s){
        if(allFilters.indexOf(s) === -1){
          allFilters.push(s);
        }
        return 0;
      })
      }
      return 0;
    })
    return allFilters;
  }

//Reducer for managing state.
function stateReducer(state, action){
  switch (action.type){
    case 'FILTER COMPANIES': //When a filter is selected.
      let s = {
        ...state,
        filterState: action.filter,
        displayedCompanies: updateCompanies(state.allCompanies, state.allFounderNames, action.filter), //Repopulate grid with companies that match the term selected in the filter.
        displayedFounders: updateFounders(state.allFounders, action.filter)
      }
      return s
    case 'RESET FILTER': //Not currently used.
      return state = initialState;
    default:
      //console.log('CASE DEFAULT');
      //Do Nothing
  }
}

  //Iterates through allCompanies, looking for companies that have a matching term to the selected filter.
  function updateCompanies(allCompanies, allFounderNames, filter){
    if(filter === 'all'){
      // so that we don't show spotlight companies twice when we're in "all" mode
      let result = [];
      for (let i = 0; i < allCompanies.length; i++) {
        if(!allFounderNames.includes(allCompanies[i].title)){
          result.push(allCompanies[i]);
        }
      }      
      return result;
    }
    let newCompanies = [];
    allCompanies.map((i)=>{
      if(i.sectors !== null){
        i.sectors.indexOf(filter);
          if(i.sectors.indexOf(filter) !== -1){
            newCompanies.push(i);
          }
      }
      return 0;
    })
    return newCompanies;
  }

  //Only show featured founders if there is no active filter.
  function updateFounders(allFounders, filter){
    if(filter === 'all' || filter === 'spotlight'){
      return allFounders
    }
    return [];
  }

  //Called by <Filter> when a tag link is clicked
  function updateFilter(newState){
    dispatch({type:'FILTER COMPANIES', filter: newState} );
  }

  state.displayedCompanies = updateCompanies(state.allCompanies, state.allFounderNames, state.filterState)
  return (
    <React.Fragment>
      <SEO {...props.data.seo} />
      <Helmet bodyAttributes={{ class: "page-portfolio on-dark" }} />
      <section className="section">
        <div className="portfolio-container">
          <div className="page-title">
            <h6 className="has-text-centered">Our Portfolio</h6>
            <h1 className="title has-text-centered">Meet the builders of Tomorrow</h1>
          </div>
          <div className="content">
            <Filter filters={state.allFilters} updateFilter={updateFilter} showSectors={state.filterState !== 'spotlight'} showAll={state.filterState === 'all'}/>
            <AnimatePresence exitBeforeEnter>
              <motion.div
                key={Math.random()}
                transition={{ duration: .33 }}
                initial={{ opacity: 0, transform: 'translateY(25px)' }}
                animate={{ opacity: 1, transform: 'translateY(0px)' }}
                exit={{ opacity: 0, transform: 'translateY(15px)' }}
              >
                <PortfolioGrid portfolio={state.displayedCompanies} featured={state.displayedFounders} filter={state.filterState} />
                { state.filterState === 'all' ? (
                  <div className="pdf-link">
                  <a href="https://hofcapital.com/blanks.pdf">{pdfLabel}</a>
                </div>
                ) : null }
              </motion.div>                  
            </AnimatePresence>            
          </div>
          {/*Enter relative path to desired page.*/}
          <UpNext url={upNext.linkurl} text={upNext.linktext}/>
        </div>
      </section>
    </React.Fragment>
  );
}

PortfolioIndexPage.propTypes = {
  portfolioitems: PropTypes.object,
  founders: PropTypes.object,
  upnext: PropTypes.object
};

export default PortfolioIndexPage;

//Query for both the companies in the portfolio and the featured founders at once
export const PortfolioQuery = graphql`
query PortfolioQuery($id: String!) {
  companies: markdownRemark(id: {eq: $id}) {
    frontmatter {
      templateKey
      pdflabel
      pdffile {
        relativePath
      }
      portfolioitems {
        companylink
        title
        featuredimage {
          childImageSharp {
            gatsbyImageData(width: 300, height: 300, quality: 100, layout: CONSTRAINED)
          }
        }
        sectors
      }
    }
  }
  seo: markdownRemark(id: {eq: $id}) {
    ...SEOFragment
  }
  founders: allMarkdownRemark(
    filter: {frontmatter: {filterKey: {eq: "founder"}}}
    sort: {fields: frontmatter___position}
  ) {
    edges {
      node {
        excerpt(pruneLength: 200)
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          teaser
          featuredimage {
            childImageSharp {
              gatsbyImageData(width: 1560, height: 900, quality: 100)
            }
          }
          logoimage {
            childImageSharp {
              gatsbyImageData(height: 225, quality: 100)
            }
          }
          whyweinvested
          linkedin
          twitter
          website
          sectors
          linkslist {
            linktext
            linkurl
          }
        }
      }
    }
  }
  upnext: allMarkdownRemark(filter: {frontmatter: {filterKey: {eq: "globalsettings"}}}) {
    nodes {
      frontmatter {
        upnextlinks {
          upnextportfolio {
            linktext
            linkurl
          }
        }
      }
    }
  }
}
      `;
