import React from 'react';
import FounderPost from './FounderPost';

const SpotlightPostLoop = (props) => {
    const { posts } = props;
    return (
      posts.edges.map(({ node: post }) => (
          <FounderPost data={post} key={post.id}/>
      ))
    )
}

export default SpotlightPostLoop